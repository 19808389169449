/*<style>*/

@import "1-base.scss";

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
   /* IE10+ specific styles go here */  
   .colkatprod .subimg img {width: 100%;}
   .ubermenu .ubermenu-submenu .ubermenu-search input.ubermenu-search-input {width: 200px !important;}
   .wrapSliderNav {display: none;}
   .formNestedRow.inP select {background-image:none;}
}

html { font-size: 62.5%; }  /* 1 rem = 10px */
//html, body {overflow-x:hidden;}

body {
	font-family: 'Open Sans', sans-serif;
	background: #fff;
	font-size:2rem;//20px if not defined in css (fallback)
	color: $gray;
	min-width: 320px;
	padding-top: 123px;
	&.home {background:#fff url(images/tlo_slider-min.png) no-repeat center top;/*background-attachment:fixed;*/}
}

body.has-revolution-slider .afterslidcontent {
	visibility: hidden;
}

img {
	max-width: 100%;
	height: auto;
}

.slid_group_allright {
	//outline:1px solid red;
	
	$w: 90px;
	&:before {
		content:'';
		position:absolute;
		width: calc(100% + #{$w});
		height: calc(100% + #{$w});
		//background:red;
		top: calc(#{-$w} / 2);
		left: calc(#{-$w} / 2);
		background-image: linear-gradient(to right, rgba(255, 255, 255, 0.36) 0%, rgba(255, 255, 255, 0) 100%);	
		z-index:-1;
		border-radius:50%;
	}
}

a {color: $red;}
a:hover {color: $gray;}
a {transition: color .1s ease-in, background .1s ease-in}

a:hover,
a:focus {
	outline: 0;
	text-decoration: none
}

h1, h2, h3, h4, h5, h6 {
	margin: 0;
}

.afterSlidRelForBg {background:#fff;}

.logoheader {img {width: 18.2rem;}}

.oberMenu .navbar {
	//background: rgba(#fff,0.5);
	//border-bottom:2px solid red;
	padding: 5rem 0 1.5rem 0;
	transition:all .3s;
}

.oberMenu .ubermenu .ubermenu-nav {display: flex;align-items:center;}
.mleftli {margin-left: auto !important;}

.oberMenu .ubermenu .ubermenu-nav > li > a:hover, .oberMenu .ubermenu .ubermenu-nav > li > a:focus, .oberMenu .ubermenu .ubermenu-nav > li > a:active {
	background: 0
}

.oberMenu .ubermenu .ubermenu-nav > li > a {
	padding-left: 2.5rem;padding-right: 2.5rem;
}
.oberMenu .ubermenu .ubermenu-nav > li:last-child > div {
	padding-right: 0 !important;
}

.oberMenu .ubermenu .ubermenu-nav > li a {
	color: $gray;
	font-size: 2rem;
	font-weight: 300;  
	
	&:hover {
		color: $red;
		.ubermenu-sub-indicator {
			//background:url(images/sub_menu_red.svg);
		}	
	}
}

.ubermenu-sub-indicators .ubermenu-submenu-type-flyout > .ubermenu-has-submenu-drop > .ubermenu-target > .ubermenu-sub-indicator, .ubermenu-sub-indicators .ubermenu-submenu-type-stack > .ubermenu-has-submenu-drop.ubermenu-has-submenu-flyout > .ubermenu-target > .ubermenu-sub-indicator {
    margin-top: -2px !important;
    right: 10px !important;
}

.ubermenu-current-menu-item > a {color:$red !important;font-weight: 700 !important;}

.oberMenu .ubermenu .ubermenu-sub-indicator {
	transition:all .3s;
	width: 1.2rem;height: .8rem;
	background:url(images/sub_menu.svg) no-repeat center;
	background-size:contain;
	color:transparent;transform:translateY(-50%);margin-top: 0 !important;
	right: 5px !important;
	
	/*right: 5px !important;
	margin-top: 0 !important;
	transform: translateY(-50%);	*/
}

.subWrapProdImg {
	height: 18rem;
	
	img, svg {max-height:100%;}
	display: flex;
	justify-content:center;
	align-items:center;
	margin-bottom: 2rem;
}

.colsubmenuprod {
	&__name {
		color: $gray;
		font-size: 1.8rem;
		font-weight: 400;
		line-height: 1.4;
	}
	
	.sub:hover {
		.colsubmenuprod__name {color: $red !important;}
		svg use {fill:$red !important;}
	}
}

.ubermenu .ubermenu-submenu .ubermenu-target {padding: 1rem 2rem;}
.ubermenu-skin-minimal .ubermenu-submenu.ubermenu-submenu-drop {border:0 !important;}

.ubermenu .ubermenu-submenu .ubermenu-search input.ubermenu-search-input {
	font-size: 2rem;font-weight: 300;
}
button.ubermenu-search-submit i {font-size:2rem;}
.ubermenu-submenu > li:last-child {border-bottom:5px solid $red;padding-bottom: 5px;}

.ubermenu-item-level-2 {
	box-shadow: 1px 11px 29px rgba(0, 0, 0, 0.1);
	&:last-child {border-bottom:0 !important;}
}


.shrink.oberMenu .navbar, .shrink.oberMenu .navbar.innerNavbar {
	padding-top: 1rem !important;
	padding-bottom: 1rem !important;
	background: rgba(255, 255, 255, 0.9) !important;
	box-shadow: 0px 5px 5px 0px rgba(#ccc, 0.27)
}

//.innerNavbar {padding-bottom:4rem !important}

/**/



/**/

img.alignright {
	float: right;
	margin: 0 0 2em 2em;
}

img.alignleft {
	float: left;
	margin: 0 2em 2em 0;
}

img.aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.alignright {
	float: right;
}

.alignleft {
	float: left;
}

.aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

a.fill_div {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	display: block;
	height: 100%;
	width: 100%;
	text-decoration: none;
}

.relative {
	position: relative;
}

footer {
	padding-top: 6rem;
	background-color: #f9f9f9;
	padding-bottom: 4rem;
}

/**/
.navigation {
	text-align: center;
	padding-top: 40px;
	padding-bottom: 40px;
	font-weight: 700;
	color: #000
}

.page-numbers {
	color: #717274;
	padding: 0 10px
}

.page-numbers:hover, .page-numbers.current {
	color: #000
}

/**/

.singleH1 {
	color: #cd373d;
	font-weight: 900
}

/**/
.formNestedRow .wpcf7-form {display: flex;flex-wrap:wrap;}
.formNestedRow .wpcf7 {width: 100%;}

$sizeCheck: 30px;
.colWyslijKopie {
	font-size:0px;
	margin-bottom: 1.5rem;
	
	
	.doMnieCheck {
		font-size: 1.4rem;
		padding-left: 1rem;
		font-weight: 500;
		width: calc(100% - 30px);
	}
	
	
	.myCheckbox {
		width: 30px;
		label {margin-bottom: 0;}
		overflow:hidden;
		input[type="checkbox"] {display: none;}
		span.wpcf7-list-item-label::before {cursor:pointer;background:url(images/checkbox_off.png) no-repeat center;width: 30px;height: 30px;display: block;}
		input[type="checkbox"]:checked + span::before {
			background-image:url(images/checkbox_on.png);
		}		
	}
}

.formNestedRow {margin-top: 2rem;}


.inF ::-webkit-input-placeholder {opacity:1;color:$gray;}
.inF ::-moz-placeholder {opacity:1;color:$gray;}
.inF :-ms-input-placeholder {opacity:1;color:$gray;}
.inF :-moz-placeholder {opacity:1;color:$gray;}

.formNestedRow p {
	font-size: 15px;
	color: #cd373d;
	margin-bottom: 0
}

.formNestedRow input:hover, .formNestedRow textarea:hover, .formNestedRow input:focus, .formNestedRow textarea:focus, .formNestedRow select:hover {
	background-color: #F5F5F5;
}

.formNestedRow input, .formNestedRow textarea, .formNestedRow select {
	border:0;
	border-bottom: 1px solid $gray;
	background-color: transparent;
	width: 100%;
	padding: .7rem 1.7rem;
	margin-top: .5rem;
	margin-bottom: .5rem;
	font-size:1.8rem;
	transition:all .3s;
	
}

.formNestedRow.inK {
	input, textarea, select {border-bottom-color:#b5b5b5;}
	textarea {height: 20rem;min-height: 20rem;max-height:20rem;}
}

.formNestedRow select {
	outline:none;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';	
	background-image:url(images/triangle_select.svg);
	background-position:right 15px center;
	background-repeat:no-repeat;
}

.formNestedRow textarea {
	resize: vertical;
	height: 100px;
	min-height: 100px;
	max-height: 200px;
	margin-bottom: 2rem;
	padding-top:1.7rem;
}

.formNestedRow input.wpcf7-submit {
	border:0;
	margin: 0;
	background: $red;
	color: #ffffff;
	font-size: 1.8rem;
	padding: .7rem 1rem;
	font-weight: 400;
	display: block;
	cursor: pointer;
	transition:all .3s;
	border-radius:10rem;
}

.formNestedRow input.wpcf7-submit:hover {
	background-color: #000;
}

.wpcf7-validation-errors {
	//border-color: #CD373D !important
}

span.wpcf7-not-valid-tip {font-size:1.5rem !important;}
div.wpcf7-response-output {
	font-size:1.4rem !important;
	margin: 1rem 15px !important;
	
}

div.wpcf7 .ajax-loader {position:absolute;right: 0;bottom: 0;}

.colCaptcha {
	margin-bottom: .5rem;
	text-align: center;
	.wpcf7-recaptcha > div {display: inline-block;}
}

div.wpcf7 .wpcf7-submit:disabled {opacity:0.5;}

.formNestedRow.inP {
	.colCaptcha {margin: 2rem 0;}
	
	select, input {border: 1px solid #b5b5b5;border-radius:3rem;margin: 1rem 0;padding: 1rem 2rem;}
	select {background-image:url(images/select.svg);background-repeat:no-repeat;background-position:right 15px center;color:$gray;}
}

/**/

/**/
.navbar-toggler {
	cursor: pointer;
	position: relative;
	width: 2.7rem;
	height: 2.5rem;
	padding: .5rem .75rem;
	font-size: 1.25rem;
	line-height: 1rem;
	border-radius: .25rem;
	background: #cd373d
}

.navbar-toggler span {
	display: block;
	position: absolute;
	height: 3px;
	width: 50%;
	background: #fff;
	border-radius: 3px;
	opacity: 1;
	left: 25%;
	transform: rotate(0deg);
	transition: .25s ease-in-out
}

.navbar-toggler span:nth-child(1) {top: 33.5%}
.navbar-toggler span:nth-child(2), .navbar-toggler span:nth-child(3) {top: 50%}
.navbar-toggler span:nth-child(4) {top: 66.5%}
.navbar-toggler.open span:nth-child(1) {
	top: 18px;
	width: 0%;
	left: 50%
}

.navbar-toggler.open span:nth-child(2) { transform: rotate(45deg)}
.navbar-toggler.open span:nth-child(3) {transform: rotate(-45deg)}
.navbar-toggler.open span:nth-child(4) {
	top: 18px;
	width: 0%;
	left: 50%
}

/**/

ul.slick-dots {
	position:absolute;
	/*transform:translateY(calc(-50% - 140px));*/
	margin:0 auto;
	right: 0;
	left: 0;
	text-align:center;
	padding: 0;
	
	li {display:inline;padding:0 20px;
		
		button {background:url(images/bullet_brown.png) no-repeat center;border:0;border-radius:50%;font-size:0px;height:15px;width:15px;cursor:pointer;outline:none;}
		
		&.slick-active button {background-image:url(images/bullet_green.png);height:15px;width:15px;}
	}
}

.wrapSliderHome {
	position:relative;
	
	.prevSlid, .nextSlid {@include v-center(absolute); z-index:5;cursor:pointer;}
	.prevSlid {left: 50px;}
	.nextSlid {right: 50px;}
}

.wrapSliderHome {
	position:relative;
	ul.slick-dots {
		top:140px;	
	}
}
/* ==== Slider Style === */
.Modern-Slider{
	position:relative;
	
	-webkit-user-select: text !important;
	-khtml-user-select: text !important;
	-moz-user-select: text !important;
	-ms-user-select: text !important;
	user-select: text;
	
	.img-fill {
		height: 640px;
		/*background-size:cover;*/
		/*background-attachment:fixed;*/
		background-color:#000;
		position:relative;
		overflow:hidden;
		
		.triangleSlider {
			position:absolute;
			bottom: -3px;
			left: 0;
			right: 0;
			
			img {
				width: 100%;
			}
			
		}
		
		.info {
			@include v-center(absolute);
			left: 0px;
			width: 100%;
			z-index:3;
		}
		
		h3{
			color:#FFF;
			font-size:130px;
			font-weight: 700;
			letter-spacing:20px;
			visibility:hidden;
			text-align:center;
			line-height: 1;
		}
		
		p{
			max-width:650px;
			font-size:51px;
			line-height: 1;
			font-weight: 700;
			letter-spacing:10px;
			margin:0 auto;
			color:#FFF;
			visibility:hidden;
			text-align:center;
		}
		
	}/*imgfill*/
}


.Modern-Slider .slick-active h3{
	animation:fadeInDown 1s both 0.5s;
	visibility:visible;
}
.Modern-Slider .slick-active p{
	animation:fadeInUp 1s both 1s;
	visibility:visible;
}

/* ==== Slick Slider Css Ruls === */
.slick-slider{position:relative;display:block;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-touch-callout:none;-khtml-user-select:none;-ms-touch-action:pan-y;touch-action:pan-y;-webkit-tap-highlight-color:transparent}
.slick-list{position:relative;display:block;overflow:hidden;margin:0;padding:0}
.slick-list:focus{outline:none}.slick-list.dragging{cursor:hand}
.slick-slider .slick-track,.slick-slider .slick-list{-webkit-transform:translate3d(0,0,0);transform:translate3d(0,0,0)}
.slick-track{position:relative;top:0;left:0;display:block}
.slick-track:before,.slick-track:after{display:table;content:''}.slick-track:after{clear:both}
.slick-loading .slick-track{visibility:hidden}
.slick-slide{display:none;float:left /* If RTL Make This Right */ ;height:100%;min-height:1px}
.slick-slide.dragging img{pointer-events:none}
.slick-initialized .slick-slide{display:block}
.slick-loading .slick-slide{visibility:hidden}
.slick-vertical .slick-slide{display:block;height:auto;border:1px solid transparent}
/**/

/*slick several like logas*/
.subwrapClientImg {
	padding-right: 30px;
	padding-left: 30px;
	text-align:center;
	height: 130px;
	line-height:130px;
}


.subwrapClientImg img {
	display:inline;
	max-height:100%;
}

/**/

/**/
.wrapPytanie {
	background:#fff;
	margin-bottom: 2rem;
	position:relative;
}

$com_padd: 3rem;

.trescakk {
	position:relative;
	cursor:pointer;
	color: $red;
	font-size: 3rem;
	font-weight: 700;
	padding: 3rem 90px 3rem $com_padd;
	background:#f9f9f9 url(images/plusfaq.svg) no-repeat right 25px center;
	background-size:4.9rem;
	&:after {content:'';left: $com_padd;right: $com_padd;bottom: 0;height: 2px;background:rgba(#fff,0.4);position:absolute;}
}

.trescakk[aria-expanded="true"] {
	background-image:url(images/minusfaq.svg);
	background-color:$red;
	color: #fff;
}

.subInCollapse {
	background:$red;
	color:#fff;
	padding: 3rem $com_padd;
}

/**/

.btnRed {
	display: inline-block;
	background:$red;
	padding: .7rem 3.0rem;
	color: #fff;
	border-radius:2rem;
	font-size: 1.9rem;
	font-weight: 600;	
	
	&:hover {color: #fff;animation:pulsered 2s;}
	
	&.ar {
		background:$red url(images/arwhite.svg) no-repeat;
		background-position:right 2rem center;
		padding: .7rem 5.5rem .7rem 2rem;
		background-size:2.4rem;
	}
	&.banner {
		background-color:#fff;color: $red;
		background-position:right 2rem center;
		background-size:3.3rem;
		padding: .7rem 6.5rem .7rem 2rem;		
		
		&.no_icon {padding-right: 4rem;padding-left: 4rem;}
		&:hover {color: $gray;}
	}
	&.newsline {
		background:#fff url(images/arrgray.svg) no-repeat;color: $gray;	
		background-position:right 2rem center;
		padding: .7rem 5.5rem .7rem 2rem;				
		
		&:hover {color: $red;animation:none;}		
		font-size:1.6rem;
		font-weight: 600;
		box-shadow: 0 .3rem 1.4rem rgba(107, 0, 0, 0.31);
		position: relative;
		top: 17px;		
	}
	&.arreduce {
		font-size:1.6rem;
		font-weight: 600;
		background:$red url(images/arwhite.svg) no-repeat;	
		background-position:right 2rem center;
		padding: .7rem 5.5rem .7rem 2rem;
		background-size:2.4rem;
	}
	&.arkusz {
		color: $gray;
		font-size: 2.4rem;
		font-weight: 700;
		background:#fff;	
		border-radius:3.5rem;
		padding: 1.5rem 4rem 1.8rem 4rem;
		
		&:hover {color: $red;}
	}
	&.btn_banner_menu {
		color: #ffffff;
		font-size: 2.2rem;
		font-weight: 300;	
		width: 100%;
		border:1px solid rgba(#fff, 0.7);
		margin-top: 1.5rem;
		margin-bottom: 1.5rem;
		border-radius:3rem;
		padding: 1.5rem 3rem;
		text-align: left;
		background-color:transparent;
		background-image:url(images/btn_banner_menu.svg);
		background-position:right 2.5rem center;
		background-repeat:no-repeat;
		transition:all .3s;
		
		&:hover {
			background-color:rgba(#fff,0.39);
		}
	}
	
}

.slid1_btn {transition:all .3s !important;font-size:2.2rem !important;line-height: 2.2rem !important;padding: 1.2rem 3.5rem !important;}
.slid2_btn {font-size:2.2rem !important;line-height: 2.2rem !important;padding: 1.2rem 3.5rem !important;}

.contpodslid {padding-top: 14rem;}
.contnewshome {padding-top: 14rem;padding-bottom: 14rem;}
.colpodslid_2 .sub {padding-left:5rem;}
.colpodslid_3 .sub {padding-left:5rem;}
.opis {
	&.f18 {font-size:1.8rem;font-weight: 300;}
	strong {font-weight: 700;}
	h1,h2,h3,h4,h5,h6 {font-weight: 300;color: $red;margin-bottom: 1rem;}
	h1,h2 {font-size:7.5rem;}
	h3,h4 {font-size:5.4rem;}
	h5,h6 {font-size:3rem;}
	font-size: 2.4rem;
	font-weight: 300;
	line-height: 1.7;	
	
	p {margin-bottom: 3rem;}
	
	ul {font-weight: 400;
		list-style:none;
		padding-left: 0;
		
		li {padding-left: 30px;background:url(images/liright.svg) no-repeat;background-position:0 1.6rem;margin-bottom: 1.5rem;}
		li a {color: $gray; &:hover {color: $red;}}
	}
	
	&.opis_f {
		font-size:2rem;
		li {margin-bottom: 0.8rem;background-position:0 1.3rem;}
		ul {margin-top: 1.5rem;}
		h1, h2 {font-size:3.3rem;}
	}
	&.in_page {
		p strong {font-weight:bolder;}
		ul {font-weight: 300;margin-bottom: 4rem;}
		li {background-image:url(images/licircle.svg);background-position:-0.6rem .4rem;padding-left: 40px;background-repeat:no-repeat;background-size:4.3rem;}
	}
	&.kont_eksp {
		h2,h3,h4 {font-size:4rem;}
	}
	&.single {
		h1,h2 {font-size:5rem;}
		h3,h4 {font-size:4rem;}
		h5,h6 {font-size:3rem;}		
	}
	&.szkoleniaurz {
		h2 {font-weight: 300;font-size:6.2rem;}
		h1 {
			color: $red;
			font-size: 14.2rem;
			font-weight: 300;	
			line-height: 1;
			margin-bottom: 6rem;
		}
	}
	&.white {color: #fff; h1,h2,h3,h4,h5,h6 {color: #fff;}}
	&.analyze {
		h1,h2,h3,h4,h5,h6 {
			font-size: 4rem;
			font-weight: 700;			
		}
	}
	&.p_increase {
		p {margin-bottom: 8rem;}
	}
	&.singleprod {
		ul {font-weight: 300;}
		li {padding-left: 20px;background:url(images/limini.svg) no-repeat;background-position:0 2.1rem;}
	}	
	&.singleprod2 {
		margin-bottom: 5rem;
		h3,h4 {font-size:4rem;}
	}
	&.opistab {
		ul {font-size:2.2rem;font-weight: 300;margin-bottom: 3rem;}
		li {padding-left: 20px;background:url(images/limini.svg) no-repeat;background-position:0 1.8rem;margin-bottom: 1rem;}
	}
}

.colnewshome {
	margin-bottom: 30px;
	.sub {
		box-shadow: 0 .8rem 5rem rgba(0, 0, 0, 0.07);
		border-radius: 1.8rem;
		background-color: #ffffff;		
		height: 100%;
		display: flex;
		flex-direction:column;
		
		.wrapImgNews img {width: 100%;height: 24rem;object-fit:cover; border-radius:1.8rem 1.8rem 0 0;}
		.contentnews {
			padding: 0 4rem;
			flex-grow:1;
			display: flex;
			flex-direction:column;
			
			.date {
				padding-top: 2rem;
				margin-bottom: 1.5rem;
				font-size: 1.8rem;font-weight: 700; 
				span{font-weight: 400; padding-right: 2.1rem;position:relative;margin-right: 1.5rem;
					&:before {content:'';width: 0.6rem;height: 0.6rem;border-radius:50%;position:absolute;background:$gray;top: 50%;transform:translateY(-50%);right: 0px;}
				}
			}
			.title {font-size:2.1rem;font-weight: 700;line-height: 1.5;}
			.wrapBtnNews {position:relative;top: 2rem;margin-top: auto;z-index: 1;}
		}
	}
}


.tos-caption {display: none;}

.contGalHome {
	a {
		display: block;
		position:relative;
		overflow:hidden;
		
		img {transition:all .4s;}
		&:hover {
			img {transform:scale(1.2);}
		}
	}
}

.secGaleria {padding-bottom: 14rem;}

.rowOneLoopGal {
	height: 60rem;
	margin-bottom: 30px;
	
	a.h100, a.h50 {
		img {width: 100%;height: 100%;object-fit:cover;} 
	}
	.h100 {height: 100%;}
	.h50 {height: calc(50% - 30px);margin-bottom: 30px;}
	.h50:last-child {height: 50%;margin-bottom: 0;}
	.col8 {height: 100%;}
}

.col8 {margin-bottom: 30px;}

.resztaImg {
	width: 100%;
	height: 30rem;
	display: block;
	
	
	img {
		width: 100%;
		height: 100%;
		object-fit:cover;
	}
}

/**/
.contekspercihome {
	margin-bottom: 14rem;
	.linki {
		.collinki:nth-of-type(even) a {background:$gray;}
		a {
			display: inline-block;
			text-align:center;
			background:$red;
			width: 100%;
			font-weight: 600;
			font-size:2.2rem;
			color: #fff;
			transition:all .3s;
			padding: 1.5rem 1rem;
			border-radius:4rem;
			
			
			&:hover {background:#000 !important;}
		}
	}
}
/**/

.contlogahome {margin-bottom: 10rem;
	.sub {margin: 0 -5rem;}
}

/*slick several like logas*/
.sublogahome {
	padding-right: 15px;
	padding-left: 15px;
	text-align:center;
	height: 13rem;
	line-height:13rem;
}


.sublogahome img {
	display:inline;
	max-height:100%;
}
/**/

.sekBaner {
	background:$red;
	padding: 3.5rem 0;
	.opBaner {
		color: #fff;
		font-size:5.4rem;
		font-weight:300;
		
		strong {font-weight: 700;}
	}
}

.plastmb0 p:last-child{margin-bottom: 0;}
.plastmb0 ul:last-child{margin-bottom: 0;}

.secPodFooter {border-top:1px solid #d8d8d8;background-color: #f9f9f9;}
.contPodFooter {
	padding-top:2rem;
	padding-bottom: 2rem;
	font-size:2rem;
	a {color: $gray; &:hover {color: $red !important;}}
	.podRight {a {color: #b4b4b4;}}
}

/**/
.circlewrap {
	height: 90px;
}

.my-circle {
    position: absolute;
    right: 0;
    left: auto;
    margin: auto;
    width: auto;
    height: 90px;
    width: 90px;
    line-height: 90px;
    text-align: center;
    display: block;
	/**/
	color:#b5b5b5;
	&:before {
		content:'Więcej';position:absolute;font-size: 20px;font-weight: 300;text-transform: uppercase;z-index:100;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);		
	}	
}
.my-circle:hover {
    cursor: pointer;
}
.my-circle .icon {
    position: relative;
    z-index: 2;
    background-color: rgb(255,255,255);
    width: 60px;
    height: 60px;
    display: block;
    border-radius: 50%;
    right: 0;
    left: 0;
    margin: 15px auto;
    line-height: 60px;
}

.my-circle .circle {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 100%;
}

.my-circle .circle:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 86px;
    height: 86px;
    border-radius: 100%;
	border:1px solid #b5b5b5;
	background:#fff;
	transition:all .3s;
}

.my-circle .circle span {
    position: absolute;
    width: 50%;
    height: 100%;
    overflow: hidden;
}

.my-circle .circle span em {
    position: absolute;
    border-radius: 999px;
    width: 100%;
    height: 100%;
    background: $red;
    transition: transform .15s linear;
}

.my-circle .circle span:first-child {
    left: 0%;
}

.my-circle .circle span:first-child em {
    left: 100%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    transform-origin: 0% 50%;
}

.my-circle .circle span:last-child {
    left: 50%;
}

.my-circle .circle span:last-child em {
    left: -100%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    transform-origin: 100% 50%;
    transition-delay: .15s;
    margin-left: 0px; 
}

.my-circle:hover .circle span:first-child em {
    transform: rotate(-180deg);
    transition: transform 0.5s ease-out;
    transition-delay: .15s;
    margin-right: -1px; 
}

.my-circle:hover .circle:after {border:1px solid transparent;}

.my-circle:hover .circle span:last-child em {
    transform: rotate(-180deg);
    transition: transform .15s linear;
}
/**/
.breadcrumbs {
	background:url(images/shadow_head.png) no-repeat center top;
	padding-top: 7rem;
	margin-top: 0rem;
	margin-bottom: 2rem;
	font-size:1.8rem;
	color: #b5b5b5;
	.current {color: $gray;}
	a {color: #b5b5b5; &:hover {color: $red;}}
}
/**/
.titlebold {font-weight: 700;font-size:10rem;color: $red;margin-bottom: 4rem;line-height: 1;
	&.titlebold {margin-bottom: 3rem;}
}
/**/
.wrapL {
	position:relative;
	overflow:hidden;
	width: 50rem;
	height: 15rem;
	padding:1.5rem 3.5rem;
	box-shadow: 0 0.7rem 2.4rem rgba(0, 0, 0, 0.14);
	border-radius:8rem;
	display: flex;
	justify-content:center;
	align-items:center;
	img {max-height:100%;}
	
	a {position:absolute;left: 0;top: 0;width: 100%;height: 100%;}
}

.colAmb {
	.sub {
		background-color: #f9f9f9;
		border-radius:20rem 20rem 0 0;
		height: 100%;
		display: flex;
		flex-direction:column;
	}
	.ambimg {margin-top: 3rem;border-radius:50%;height: 28.5rem;width: 100%;width: 28.5rem;margin-bottom: 1rem;}
	.redString {
		background:$red;padding: .4rem 1rem;
		&, a {color: #ffffff;}
		font-size: 2.2rem;
		font-weight: 300;
		margin-top: auto;
	}
	.st1 {
		color: $gray;
		font-size: 2.1rem;
		font-weight: 300;
		font-style: italic;		
	}
	.st2 {
		color: $gray;
		font-size: 2rem;
		font-weight: 300;	
		margin-bottom: 2rem;
	}
	.imie {
		color: $red;
		font-size: 2.8rem;
		font-weight: 700;	
		margin-bottom: .8rem;
	}
}

.absnaszafirma {
    position: absolute;
    top: -9.5rem;
    right: 0;
    width: 50%;
	
	&.z-1 {z-index: -1;}
}

.contnaszafirma {margin-bottom: 15rem;}

.sekKontakt {
	background-color: #f9f9f9;
	padding: 7rem 0;
	margin-bottom: 7rem;
}

.acf-map {height: 47.6rem;}
.contEkspOpis {padding: 10rem 0;margin-top: 5rem;}

.ekspsvg {position:absolute;z-index:-1;left: 0;right: 0;margin:auto;top: 0;}

.colEksperci {
	.sub {
		.wrapimgeksp img {width: 100%;height: 33rem;object-fit:cover;}
		box-shadow: 0 .6rem 2.3rem rgba(0, 0, 0, 0.09);
		border-radius: 2.6rem;
		background-color: #ffffff;	
		height: 100%;
		overflow:hidden;
		
		.contentekspcol {
			padding: 2rem 3rem;
			
			.imie {
				font-size: 2rem;
				font-weight: 300;
				font-style: italic;				
			}
			.st {
				color: $red;
				font-size: 3rem;
				font-weight: 700;				
			}
			.opis {
				font-size: 1.6rem;
				font-weight: 300;
				line-height: 1.9;
			}
			.mail {
				font-size: 2.2rem;
				font-weight: 300;			
				background:url(images/kontakt_mail.svg) no-repeat;
				background-position:0 2rem;
				padding: 1rem 0 0 35px;
				background-size: 2.4rem;
			}
			.tel {
				font-size: 2.2rem;
				font-weight: 300;		
				background:url(images/kontakt_tel.svg) no-repeat;
				background-position:0 1.7rem;
				padding: 1rem 0 0 35px;		
				background-size:2.2rem;
			}
		}
	}
}

.conteksppage {margin-bottom: 7rem;}

/**/
.imgSingleWrap {
	text-align:center;
	margin-bottom: 3rem;
	margin-top: 2rem;
	
	img {
		width: 100%;
		object-fit:cover;
		height: 50rem;
	}
}

.titlesingle {
	color: $red;
	font-size: 3.6rem;
	font-weight: 700;	
	margin-bottom: 1rem;
}

.blokdatesingle {
	font-size: 2rem;
	font-weight: 400;	
	margin-bottom: 3rem;
}

.contautorsingle {
	margin-bottom: 6rem;
	.sub {
		background-color: #f9f9f9;
		padding: 5rem;
	}
	
	.titleautor {
		color: $red;
		font-size: 2.8rem;
		font-weight: 700;	
		margin-bottom: 1.5rem;
	}
	.opisautor {
		font-size: 2.2rem;
		font-weight: 300;	
		line-height: 1.7;
	}
	
	.imgautor img {
		border-radius:50%;
		$sizeautor: 28.5rem;
		height: $sizeautor;
		width: $sizeautor;
		object-fit:cover;
	}
	
	.stringautor {
		font-size: 2rem;
		font-weight: 700;		
	}
}

.conttimeline {margin-bottom:6rem;}
.timeline_items_wrapper {
	
}
#tl1 {box-shadow: 1rem 1.4rem 2.4rem rgba(0, 0, 0, 0.09);background:url(images/waves.svg) no-repeat center bottom;background-position: 50% calc(100% + 15px);}
.timeline_rollover_bottom {display: none !important;}
#tl1 .my_timeline_content {position:relative !important;bottom: 0 !important;}
#tl1 .item, #content #tl1 .item {height: auto !important;background:transparent !important;}
.my_post_date {display: none !important;}
#tl1.my_style_style_4 .item img {position:relative !important;}
#tl1 .item span, #content #tl1 .item span {position:relative !important;top: 0 !important;bottom: 0 !important;}
#tl1 .read_more {display: none !important;}
#tl1 .item {}

.imgnewsline {width: 100%;height: 16rem;object-fit:cover;}
.titlenewsline {
	margin-top: 1rem;
	margin-bottom: 1rem;
	a {
		font-size: 2rem;
		font-weight: 700;	
		color: #fff;
	}
}
.timenewsline {
	font-size: 1.8rem;
	font-weight: 400;
	color: #fff !important;
	background: url(images/timesvgnewsline.svg) no-repeat;
	padding-left: 30px;
	background-position:0 50%;
	display: inline-block;
	background-size:2.4rem;
}

.t_line_wrapper h4 {font-weight: 300 !important;color: $gray !important;}
.timeline.flatLine a.t_line_node, #content .timeline.flatLine a.t_line_node {top: -2.7rem !important;}
#tl1 .t_node_desc span, #content #tl1 .t_node_desc span {background:$red !important;font-weight: 300 !important;}
#tl1 .t_node_desc span::after, #content #tl1 .t_node_desc span::after {border-top-color: $red !important;}

.timeline#tl1 .t_line_node::after {background:$red !important;}

.my_timeline_content {
	.sub {
		padding: 0 15px;
		position:relative;
		
		&:before {content:'';position:absolute;width: 100%;left: 0;bottom: 0;height:calc(100% - 5rem);background:$red;}
		
		& > * {position:relative;z-index: 1;}
	}
}

.checklinkmore {margin-bottom: -10px;}
.item {overflow: initial !important;}
#tl1 .item:hover, #content #tl1 .item:hover {box-shadow:none !important;}

//
.sek4news {
	&:before, &:after {content:'';position:absolute;z-index:-1;background-color: #b5b5b5;opacity:0.57;}
	&:before {left: 50%;top: 4rem;bottom: 4rem;transform:translateX(-50%);width: 2px;}
	position:relative;
	
	.container {
		&:after {content:'';position:absolute;z-index:-1;background-color: #b5b5b5;opacity:0.57;}
		&:after {left: 15px;right: 15px;top: 50%;transform:translateY(-50%);height: 2px;}
		position:relative;
		margin-bottom: 10rem;
	}
}

.head4news {
	color: $red;
	font-size: 4rem;
	font-weight: 700;
	margin-bottom: 3rem;
	
	&.gray {color: $gray;}
}

.colOne4News {margin-bottom: 4rem;margin-top: 4rem;}

.colInNewsLoop {
	.sub {height: 100%;display: flex;flex-direction:column;}
	margin-bottom: 3rem;
	.time {
		color: $gray;
		font-size: 1.5rem;
		font-weight: 400;
		background:url(images/timegray.svg) no-repeat;
		background-position:0 .2rem;
		padding-left: 30px;
		background-size:2rem;
	}
	img.obr  {
		width: 100%;height: 16rem;object-fit:cover;margin-bottom: 1rem;
	}	
	.title  {
		margin-bottom: 1.5rem;
		a {
			font-size: 1.9rem;
			font-weight: 700;		
		}
	}
}

.singleszkoleniaurz {
	color: $red;
	font-size: 6.2rem;
	font-weight: 300;
}

.colLeftSingleSzkolUrz {margin-top: 6rem;}
.rowSingleSzkolUrz {margin-bottom: 6rem;margin-top: 4rem;}

.absszkoleniaurz {
    position: absolute;
    top: 0px;
    right:0;
    width: 50%;
	overflow:hidden;
	
	img {position:relative;left:5rem;width: 61rem;}
}

.contpoisakrwyn {
	margin-bottom: 14rem;
	
	.sub {
		padding: 5rem 0 3rem 0;
		background:url(images/zdjecie_wewnatrz.png) no-repeat center;
		border-radius:20rem;
		overflow:hidden;
	}
}

.contszkoleniaurz {margin-bottom: 14rem;}

.titleszkolwyn {
	font-size:6.2rem;
	color: $red;
	font-weight: 300;
	margin: 4rem 0 7rem 0;
}

.rowAnalyze {margin-bottom: 15rem;}
.contszkoleniaanalyze {margin-bottom: 10rem;}

.rowsingleprod1 {margin-bottom: 10rem;}
.titlesingleprod {color: $red;font-weight: 300;font-size:9.6rem;margin-bottom: 2rem;}

/**/
.wrapSliderFor {
	margin-bottom: 20px;
	position:relative;
	.slick-track {text-align: center;}
	
	.singleprodgalleft, .singleprodgalright {
		position:absolute;
		top: 50%;
		transform:translateY(-50%);
		z-index: 2;
		cursor:pointer;
		border-radius:50%;
		width: 5.2rem;
		
		&:hover {animation:pulsered 2s;}
	}
	.singleprodgalleft {left: -2.5rem;}
	.singleprodgalright {right: -2.5rem;}
	
	.singleProdBigImg {
		background-color: #f0efed;
		
		.sub {
			padding: 2rem;
			height: 51rem;
			display: flex;	
			align-items:center;
			justify-content:center;
			
			img {
				max-height:100%;
				width: auto;
				object-fit:cover;
			}
		}
	}	
}

.wrapSliderNav {
	.slick-track {display: flex;align-items:center;}
	
	margin-left: -10px;margin-right: -10px;
	.slick-slide {padding: 0 10px;}	
	
	.slider-nav .sub {
		cursor:pointer;
		background-color: #f0efed;
		height: 15rem;
		padding:10px 10px;	
		img {transition:all .3s;height:100%;width: 100%;object-fit:cover;}
		&:hover img {opacity:0.8;}
	}	
}
/**/

.rowsinglegal2 {margin-bottom: 9rem;}
.colsinglegal2 {
	margin-bottom: 30px;
	img {
		height: 33.5rem;
		width: 100%;
		object-fit:cover;
	}
}

.seksingleprodopis {
	background-color: #f9f9f9;
	padding: 6rem 0;
	margin-bottom: 12rem;
}

/**/

.contbannermenu {
	margin-bottom: 14rem;
	
	.sub {
		padding: 7rem 0 7rem 0;
		background:url(images/zdjecie_wewnatrz.png) no-repeat center;
		border-radius:20rem;
		overflow:hidden;
	}
}

/**/

.contnavssingleprod {
	margin-bottom: 15rem;
	
	.nav-tabs {
		&:after {z-index: -1;width: 100%;height: 4px;background:#ededed;content:'';position:absolute;bottom: 0;left: 0;}
		position:relative;
		margin-bottom: 7rem;
		border-bottom:0;
		
		li {margin-right: 5rem;}
		
		a {
			transition:all .3s;
			position:relative;
			z-index: 1;
			display: block;
			border-bottom:4px solid transparent;
			font-size: 3rem;
			font-weight: 300;
			padding-bottom: 2rem;
			&.active.show {color: #181616;border-bottom-color:#181616;}
		}
	}
}

.coltabpar_left {
	color:$red;
	font-size: 2.4rem;
	font-weight: 300;
}

.rowtabpar {
	position:relative;
	padding-bottom: 5.6rem;
	margin-top: 5.6rem;
	
	&:before {content:'';position:absolute;right: 30px;left: 30px;bottom: 0;background:#ededed;height: 4px;z-index: 2;}
}

.singleprodnagl {
	color: $red;
	font-size: 4rem;
	font-weight: 700;	
	position:relative;
	bottom:7.3rem;
	span {background:#fff;padding: 0 3rem;}
}

.colprodblok {
	margin-bottom: 3rem;
	.sub {
		background:$red;
		color: #fff;
		height: 100%;
		padding: 4rem 1.5rem;
		display: flex;
		flex-direction:column;
		border-radius:4rem;
	}
	.nazwa {
		font-size: 2.4rem;
		font-weight: 300;	
		margin: auto;
	}
	
	img {max-height:70px;}
}

.colSingleProdBlokMain {
	margin-bottom: 10rem;
	$w:80px;
	&:before {content:'';position:absolute;
		width: calc(100% + #{$w});
		height: calc(100% + #{$w});
		top: calc(#{-$w} / 2);
		left: calc(#{-$w} / 2);		
		border:1px solid $red;
		border-radius:6rem;
		z-index: -1;
	}
}

.conttaxkatprod {margin-bottom: 8rem;margin-top: 5rem;}

.colkatprod {
	margin-bottom: 5rem;
	.subimg {
		padding: 3rem;
		height: 42rem; 
		border: 1px solid rgba(#707070, 0.76);margin-bottom: 2rem;
		display: flex;
		justify-content:center;
		align-items:center;		
		img {max-height:100%;}
	}
	.titlekatprod a {font-weight: 700;font-size:3rem;}
}

.colformporownaj {
	margin: 3rem 0 6rem 0;
	.sub {
		box-shadow: 0 .6rem 2.3rem rgba(0, 0, 0, 0.09);
		border-radius:2.6rem;
		padding: 3rem;
	}
}

.opisporownaj {
	font-size:3.7rem;
	font-weight:300;
	p {margin-bottom: 0rem;}
	
	strong {font-weight: 700;}
}

.ubermenu .ubermenu-colgroup .ubermenu-column, .ubermenu .ubermenu-custom-content-padded, .ubermenu .ubermenu-nonlink, .ubermenu .ubermenu-retractor, .ubermenu .ubermenu-submenu-padded, .ubermenu .ubermenu-submenu-type-stack > .ubermenu-item-normal > .ubermenu-target, .ubermenu .ubermenu-target, .ubermenu .ubermenu-widget, .ubermenu-responsive-toggle {
    padding: 1.5rem 2rem;
}
.ubermenu .ubermenu-submenu .ubermenu-search input.ubermenu-search-input {padding: 1.4rem 5rem 1.4rem 2.5rem;}


#catapult-cookie-bar {font-size: 13px !important;}

@media (max-width:1879.98px) {
	html {font-size:55%;}
	.subWrapProdImg {margin-top: 2rem;}
	.colInNewsLoop .time {padding-left: 25px;}
	.absnaszafirma {top: -9.2rem;}
}
@media (max-width:1589.98px) {
	.absnaszafirma {top: -9.0rem;}
	html {font-size:50%;}
	.oberMenu .ubermenu .ubermenu-nav > li > a {padding-left: 2.0rem;padding-right: 2.0rem;}
	.oberMenu .ubermenu .ubermenu-sub-indicator {right: 2px !important;}
	.oberMenu .ubermenu .ubermenu-nav > li:last-child > div {padding-left: 2rem !important;}	
	
	.oberMenu .navbar {padding-top: 3rem;}
	body {padding-top: 100px;}
	.tp-bullets {top:calc(100% + 10px) !important;}
	.subWrapProdImg {}
	.subWrapProdImg svg {display: block;}
	
	.subWrapProdImg {height: 14rem;}
	.circlewrap {/*right: 25px;*/}
	.rowOneLoopGal {height: 40rem;}
	.contekspercihome .linki a {font-size:1.9rem;}
	.sekBaner .opBaner {font-size:4.4rem;}
	.titlebold {font-size:7rem;}
	.breadcrumbs {margin-top: -3rem;}
	.opis.szkoleniaurz h1 {font-size:10rem;}
	.imgSingleWrap img {height: 40rem;}
}

@media (max-width: 1199.98px) {
	.oberMenu .ubermenu .ubermenu-nav > li a {font-size:1.6rem;}
	.logoheader img {width: 14rem;}
	.colF {margin-bottom: 30px;}
	.contnavssingleprod .nav-tabs li {margin-right: 3rem;}
	.contnavssingleprod .nav-tabs {margin-bottom: 4rem;}
	.contnavssingleprod .nav-tabs a {font-size:2rem;}
	.rowtabpar::before {right: 15px;left: 15px;}
	.colLeftSingleSzkolUrz {margin-top: 0;}
	.opis.szkoleniaurz {h2, h3 {font-size:5rem;}}
	.absszkoleniaurz img {width: 50rem;}
	.sek4news::before, .sek4news .container::after {display: none;}
	.titlebold.titlebold {margin-bottom: 1rem;}	
} /*1200*/

@media (max-width: 991.98px) {
	.absnaszafirma {display: none;}
	html {font-size:45%;}
	.slid1_btn {font-size:1.5rem !important;line-height: 1.5rem !important;padding: 1.2rem 2.5rem !important;}
	.slid2_btn {font-size:1.5rem !important;line-height: 1.5rem !important;padding: 1.2rem 2.5rem !important;}	
	.colpodslid_2 .sub, .colpodslid_3 .sub {padding-left: 0;}
	.opis h1, .opis h2 {font-size:6.5rem;}
	.breadcrumbs {margin-top: -4rem;}
	.btn_banner_menu {width: calc(100% - 30px) !important;}
	.contbannermenu .sub {border-radius:5rem;}
	.contnaszafirma {margin-bottom: 5rem;}
}  /*992*/

@media (max-width: 767.98px) {
	.navbar-expand-md > .container {
		padding-left: 15px;
		padding-right: 15px
	}
.ubermenu-skin-minimal.ubermenu-responsive-toggle {
	padding: 0 !important;
	display: flex !important;
	flex-direction: row-reverse;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
	margin-top: 10px;
	
	.fa-bars {font-size:30px !important;color: $gray;margin: 0 !important;}	
}	
.oberMenu .navbar {
	padding-top: 1rem !important;
	padding-bottom: 1rem !important;
	background: rgba(255, 255, 255, 0.9) !important;
	box-shadow: 0px 5px 5px 0px rgba(204, 204, 204, 0.27);	
}
.logomobile {width: 140px;}
.oberMenu .ubermenu .ubermenu-nav > li > a {padding: 0 0 15px 0 !important;font-size: 15px;}
.oberMenu .ubermenu .ubermenu-nav > li:last-child > div {padding:0 !important;}
.oberMenu .ubermenu .ubermenu-nav > li a {font-size: 15px;}
.ubermenu-sub-indicators .ubermenu-has-submenu-drop > .ubermenu-target > .ubermenu-sub-indicator {position:relative !important;left: 7px !important;right: 0 !important;transform:none !important;top: 5px !important;}
//
.subWrapProdImg {height: 8rem;margin-bottom: 5px;}
.my-circle {left: 0;}
.contpodslid {padding-top: 5rem;}
.contnewshome {padding-top: 5rem;padding-bottom: 5rem;}
//
.rowOneLoopGal {height: auto;margin-bottom: 0;}
.rowOneLoopGal .col8 {height: auto;text-align: center;}
.rowOneLoopGal a.h100 img, .rowOneLoopGal a.h50 img {height: auto;max-height:200px;width: auto;}
.rowOneLoopGal .h50 {height: auto;}
.rowOneLoopGal .h50:last-child {height: auto;}
.rowOneLoopGal a.h100 img, .rowOneLoopGal a.h50 img {width: 100%;}
.col8, .h50 {margin-bottom: 15px !important;}
.rowOneLoopGal .h50:last-child {margin-bottom: 0 !important;}
.wiwi img {width: 50px;position:relative;bottom: 2px;}
.logof {
	margin-bottom: 5px;
	img {width: 100px;}
}
footer {padding-bottom: 0;padding-top: 4rem;}
.opis h1, .opis h2 {font-size: 5rem;}
.opis h3, .opis h4 {font-size: 4rem;}
.opis {font-size:2rem;}
.opis ul li {background-position:0 1.1rem;}
.opis p {margin-bottom: 2rem;}
.secGaleria {padding-bottom: 4rem;}
.coleksperthomeimg img, .colpodslid_1 img, .colexpobr img {max-height:250px;}
.collinki.mb30 {margin-bottom: 15px;}
.contekspercihome {margin-bottom: 4rem;}
.contlogahome {margin-bottom: 3rem;}
.sekBaner .opBaner {font-size: 3.4rem;line-height: 1.2;}
.contPodFooter {font-size: 1.8rem;}
.breadcrumbs {background:none;margin-top: 0;padding-top: 0;}
.titlebold {font-size: 5rem;line-height: 1.2;}
.opis.in_page li {background-position: -0.6rem 0.2rem;padding-left: 30px;}
.wrapL {width: 40rem;height: 10rem;}
.colEksperci .sub .contentekspcol {.tel, .mail {padding-left: 25px;}}
.colEksperci .sub .contentekspcol .tel {background-position: 0 1.4rem;}
.colEksperci .sub .contentekspcol .mail {background-position: 0 1.9rem;}
.colEksperci.mb60 {margin-bottom: 30px;}
.opis.opis_f li {background-position: 0 1.1rem;}
.titlesingleprod {font-size:5rem;}
.contnavssingleprod .nav-tabs a.active.show {border-bottom-color:transparent;}
.singleprodnagl {bottom: 0;margin-bottom:3rem;}
.colSingleProdBlokMain::before {display: none;}
.contnavssingleprod {margin-bottom: 5rem;}
.rowtabpar {padding-bottom: 2rem;margin-top: 2rem;}
.opis.opistab li {background-position: 0 1.6rem;}
.seksingleprodopis {margin-bottom: 3rem;}
.contbannermenu {margin-bottom: 6rem;padding: 3rem 0;}
.colSingleProdBlokMain {margin-bottom: 3rem;}
.wrapSliderFor .singleProdBigImg .sub {height: 200px;}
.rowsingleprod1 {margin-bottom: 5rem;}
.colsinglegal2 img {height: 200px;width: auto;}
.colsinglegal2 {text-align:center;}
.rowsinglegal2 {margin-bottom: 4rem;}
.opis.singleprod li {background-position:0 1.5rem;}
.colkatprod .subimg {height: 200px;}
.conttaxkatprod {margin-bottom: 3rem;margin-top: 3rem;}
.contpoisakrwyn .sub {border-radius:5rem;}
.contpoisakrwyn {margin-bottom: 6rem;}
.contszkoleniaurz {margin-bottom: 4rem;}
.opis.szkoleniaurz h1 {font-size:6rem;margin-bottom: 10px;}
.opis.szkoleniaurz {h2, h3 {font-size:4rem;}}
.rowSingleSzkolUrz {margin-bottom: 4rem;margin-top: 0;}
.titleszkolwyn {font-size:5rem;margin-top:0;margin-bottom: 2rem;}
.rowAnalyze {margin-bottom: 3rem;}
.opis.p_increase p {margin-bottom: 3rem;}
.tos-pagination.tos-thumbnails {display: none;}
.contszkoleniaanalyze {margin-bottom: 5rem;}
.colCaptcha {overflow:hidden;}
#tl1 .t_line_holder {display: none;}
.colOne4News {margin-bottom: 0;margin-top: 0;}
.sek4news .container {margin-bottom: 3rem;}
.imgSingleWrap img {height: 200px;}
.contautorsingle .sub {padding: 15px;}
.contautorsingle .opisautor {font-size:1.8rem;}
.navigation {padding-top: 10px;padding-bottom: 0;}
.trescakk {font-size:2.5rem;}
.colAmb.mb60 {margin-bottom: 30px;}
.contnaszafirma {margin-bottom: 2rem;}
.acf-map {height: 250px;}
.contEkspOpis {padding: 5rem 0;margin-top: 0rem;}
.sekKontakt {padding: 5rem 0 3rem 0;margin-bottom: 4rem;}
}  /*768*/

@media (max-width: 575.98px) {
	
}  /*576*/

@media (max-width: 480px) {
	
}  /*480*/

@media (max-width: 350px) {
	
}  /*350*/

@media (min-width: 1200px) {
	.col25 {padding-right: 25px;padding-left: 25px;}
	.row25 {margin-right: -25px;margin-left: -25px;}
	/**/
	.col30 {padding-right: 30px;padding-left: 30px;}
	.row30 {margin-right: -30px;margin-left: -30px;}
	/**/
	.col60 {padding-right: 60px;padding-left: 60px;}
	.row60 {margin-right: -60px;margin-left: -60px;}
	/**/
	.col10 {padding-right: 10px;padding-left: 10px;}
	.row10 {margin-right: -10px;margin-left: -10px;}
	/**/
	.col50 {padding-right: 50px;padding-left: 50px;}
	.row50 {margin-right: -50px;margin-left: -50px;}
	/**/
	.col38 {padding-right: 38px;padding-left: 38px;}
	.row38 {margin-right: -38px;margin-left: -38px;}	
} /*1200*/

/*</style>*/		